<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload.sync="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="main-deposit" v-if="formFlag && !solidPayments.show">
      <div class="max">
        <el-steps direction="vertical">
          <el-step icon="red"></el-step>
          <el-step icon="blue"></el-step>
          <el-step icon="red"></el-step>
        </el-steps>
        <div class="depositFunds-box">
          <div class="form-left">
            <div class="content">
              <div class="info-box">
                <p>{{ $t('deposit.cc.header') }}</p>
                <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              </div>
              <div class="form_main">
                <p class="title">{{ $t('deposit.cardpay.form.header') }}</p>
                <el-form
                  label-position="top"
                  :model="solidpayForm"
                  ref="solidpayForm"
                  status-icon
                  :rules="solidpayRules"
                >
                  <div class="form-box">
                    <ul class="clearfix">
                      <li>
                        <AccountNumber
                          :supportedCurrenciesList="validCurrencies"
                          @setCurrency="setCurrency"
                          @setAccountNumber="setAccountNumber"
                        ></AccountNumber>
                      </li>
                      <li>
                        <el-form-item :label="setAmtLabel(accountCurrency, solidpayForm.amount)" prop="amount">
                          <numeric-input
                            v-model="solidpayForm.amount"
                            :currency="accountCurrency"
                            :precision="2"
                          ></numeric-input>
                        </el-form-item>
                      </li>
                    </ul>
                    <ul class="clearfix">
                      <li>
                        <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                          <el-input v-model="solidpayForm.notes" data-testid="imptNotes"></el-input>
                        </el-form-item>
                      </li>
                    </ul>
                  </div>
                  <el-form-item>
                    <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="form-right creditcard">
            <div class="logo cc cc2way"></div>
          </div>
        </div>
      </div>
    </div>
    <form v-else :action="solidPayments.shopperResultUrl" class="paymentWidgets" data-brands="VISA MASTER"></form>
  </CreditCardTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import creditCard from '@/mixins/page/deposit/creditCard';
import { apiCc_payment_solid } from '@/resource';

export default {
  name: 'Solidpayment',
  mixins: [mixin, creditCard],
  components: {
    NumericInput,
    AccountNumber,
    CreditCardTemplate,
    DepositInfo
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      solidpayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      solidpayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'EUR', 'GBP', 'AUD', 'NZD', 'SGD', 'HKD', 'USC'],
      solidPayments: { shopperResultUrl: '', jsUrl: '', show: false },
      showNote: false,
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst4'),
        this.$t('deposit.reminder.inst5'),
        this.$t('deposit.reminder.inst6')
      ]
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.solidpayForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiCc_payment_solid(
        {
          mt4Account: this.solidpayForm.accountNumber,
          depositAmount: this.solidpayForm.amount,
          applicationNotes: this.solidpayForm.notes
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['solidpayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                const data = JSON.parse(result.data.msg);
                this.solidPayments.shopperResultUrl = data.shopperResultUrl;
                this.solidPayments.jsUrl = data.jsUrl;

                this.insertJs(this.solidPayments.jsUrl);
                this.solidPayments.show = true;
              } else if (result.data.code == 300) {
                this.formFlag = false;
                this.resultFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/pages/deposit/ccDefault.scss';
</style>
